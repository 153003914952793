$(document).on('turbolinks:load', function() {
  $('body').on('click', '[data-action=click-add-association]', function(e) {
    e.preventDefault();
    var $content =  $(this).siblings('.array-field-template').clone();
    var datestamp = new Date().valueOf();
    $content.find('label, input, select').each(function(i, el) {
      $.each(el.attributes, function(j, attr) {
        if (attr.specified) {
          // Only replace the first instance of the placeholder in each attribute we find.
          // Nested templates need to keep subsequent placeholders so they can be replaced
          // when the inner template is used to render a field.
          $(el).prop(attr.name, attr.value.replace(/(\-|_)999999/, datestamp));
        }
      });
    })

    var $target = $(this).siblings('.target');
    $target.append($content.html())
    $target.trigger('config:add_field');

    // If this is an object, it only should have one, so hide the add link when we add one.
    if ($(this).data('object')) {
      $(this).addClass('hidden');
    }
  });

  // Remove the field templates before submission.
  $('body').on('submit', '.add-on-config-form', function() {
    $('.array-field-template').remove();
  });

  $('body').on('click', '[data-action=click-remove-association]', function(e) {
    e.preventDefault();
    var required = $(this).data('required');

    if (required && $(this).parent().parent().find('> .add-on-array-item').length == 1) {
      alert("You need at last one " + $(this).parents('[data-field-name]').data('field-name'));
    }
    else {
      // If this is an object, it only should have one, so show the add link when we remove it.
      if ($(this).data('object')) {
        $(this).closest('.object-field-wrapper').find('[data-action=click-add-association]').removeClass('hidden');
      }
      $(this).closest('.add-on-array-item').remove();
    }
  });

  $('.view-code').click(function() {
    $(this).parent().find('.code-preview').toggleClass('hidden');
    $(this).find('span').toggleClass('hidden');
  });

  $('.view-config').click(function() {
    $(this).parent().find('.config-preview').toggleClass('hidden');
    $(this).find('span').toggleClass('hidden');
  });
});

$(document).on('turbolinks:load', function() {
  $('.disable-switch').click(function(el) {
    var that = this;
    $('body .tooltip').remove();

    // Clicks seem to register twice, so stop that.
    if (window.isSwitching) {
      return;
    }

    window.isSwitching = true;
    setTimeout(function() {
      window.isSwitching = false;
    }, 50);

    function setTooltip(disabled) {
      var newTitle;

      if (disabled) {
        newTitle = $(that).data('disabled-title');
      }
      else {
        newTitle = $(that).data('enabled-title');
      }

      $(that).tooltip('hide')
        .attr('data-original-title', newTitle)
        .tooltip('_fixTitle');
    }

    // Are we disabling or enabling this?
    var checked = $(this).find('[type=checkbox]').is(':checked')

    var objectName;
    var key;

    // Let us use this switch with values configured in data-variables.
    if ($(this).data('object-name')) {
      objectName = $(this).data('object-name');
    }
    else {
      objectName = 'add_on';
    }

    if ($(this).data('key')) {
      key = $(this).data('key');
    }
    else {
      key = 'disabled';
    }

    // Originally this code was passing disabled: true/false
    // So the 'on' state of the switch equated to false.
    // But if you want the on state to equate to passing 'true', set this data variable.
    if ($(this).data('on-is-true')) {
      value = !checked;
    }
    else {
      value = checked;
    }

    var data = {}
    data[objectName] = {};
    data[objectName][key] = value;

    $.ajax({
        type: 'PATCH',
        url: $(this).data('path') + '.json',
        data: JSON.stringify(data),
        dataType: "json",
        contentType: "application/json"
      }).done(function(data) {
        console.log("Success.");
        setTooltip(checked)
        // Update hidden filter column.
        var $parentTr = $(that).parents('tr.add-on-instance');
        $parentTr.find('.disabled-cell').text(value ? 'disabled' : 'enabled');
        // Tell datatables its changed.
        var $table = $(that).parents('.datatable-enabled').DataTable();
        $table.rows($parentTr).invalidate();
      }).fail(function() {
        alert("Sorry your data wasn't saved. Please check your connectivity and try again.");
        // Revert UI to avoid confusion.
        $(that).find('[type=checkbox]').prop('checked', checked);
      });
  });
});

// Field type specifid functionality.
$(document).on('turbolinks:load', function() {
  function flashGreen($el) {
    $el.addClass('flash-border-green');
    setTimeout(function () {
      $el.removeClass('flash-border-green');
    }, 500);
  }

  $('body').on('keyup paste', 'input[data-field-type=product_id]', function() {
    var that = this;
    setTimeout(function () {
      var val = $(that).val();
      // Find integers in strings that match .../products/THE_ID/...
      var idMatch = val.match(/\/products\/(\d{3,})\//);
      if (idMatch) {
        $(that).val(idMatch[1]);
        // Visual cue to show user something has happened.
        flashGreen($(that));
      }
    }, 200);
  });

  $('body').on('keyup paste', 'input[name="domain[domain]"]', function() {
    var that = this;
    setTimeout(function () {
      var val = $(that).val();
      // Find integers in strings that match .../products/THE_ID/...
      var idMatch = val.match(/^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/);
      if (idMatch) {
        $(that).val(idMatch[1].replace('https://','').replace('http://',''));
        // Visual cue to show user something has happened.
        flashGreen($(that));
      }
    }, 200);
  });
})
