$(document).on('turbolinks:load', function() {
  $('.copy-code').click(function() {
    var textarea = $(this).parent().find('.code').get(0)
    textarea.select();
    textarea.setSelectionRange(0, 99999);
    document.execCommand("copy");

    $(this).attr('data-preclick-title', $(this).attr('data-original-title'))

    $(this).tooltip('hide')
            .attr('data-original-title', 'Copied!')
            .tooltip('_fixTitle')
            .tooltip('show');
  });

  $('.copy-code').on('mouseout', function() {
    $(this).tooltip('hide')
            .attr('data-original-title', $(this).data('preclick-title'))
            .tooltip('_fixTitle')

  })
});
