$(document).on('turbolinks:load', function() {
  $('body').on("click", "form.admin-thumbnails a[data-toggle=tab]", function() {
    if ($(this).attr('href') == '#thumbnail-url') {
      $('#add_on_config_use_thumbnail_url').val(true);
    }
    else {
      $('#add_on_config_use_thumbnail_url').val(false);
    }
  });
});


