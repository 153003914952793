require("bootstrap-daterangepicker/daterangepicker.css");

import 'bootstrap-daterangepicker';

function enableDateFields($scope) {
  function clearDate(event) {

    // don't submit the form.
    event.preventDefault();

    // find a bunch of relevant elements.
    var $dateField = $(event.target).closest('.date-input').find('input');

    // clear the cloudinary id.
    $dateField.val(null);

  }

  $scope.find('input.single-daterange').daterangepicker({
    singleDatePicker: true,
    autoUpdateInput: false,
    locale: {
        cancelLabel: 'Clear',
        format: 'DD MMM YYYY',

    }
  });

  $scope.find('input.single-daterange').on('apply.daterangepicker', function(ev, picker) {
      $(this).val(picker.startDate.format('DD MMM YYYY'));
  });

  $scope.find('input.single-daterange').on('cancel.daterangepicker', function(ev, picker) {
      $(this).val('');
  });

  $scope.find(".date-input button.clear").click(clearDate);
}

function enableDateTimeFields($scope) {
  function clearDateTime(event) {

    // don't submit the form.
    event.preventDefault();

    // find a bunch of relevant elements.
    var $dateTimeField = $(event.target).closest('.date-time-input').find('input');

    // clear the cloudinary id.
    $dateTimeField.val(null);

  }

  $scope.find('input.single-datetime').daterangepicker({
    singleDatePicker: true,
    autoUpdateInput: false,
    timePicker: true,
    drops: 'up',
    locale: {
        cancelLabel: 'Clear',
        format: 'DD MMM YYYY hh:mm A',
    }
  });

  $scope.find('input.single-datetime').on('apply.daterangepicker', function(ev, picker) {
      $(this).val(picker.startDate.format('DD MMM YYYY hh:mm A'));
  });

  $scope.find('input.single-datetime').on('cancel.daterangepicker', function(ev, picker) {
      $(this).val('');
  });

  $scope.find(".date-time-input button.clear").click(clearDateTime);
}

$(document).on('turbolinks:load', function() {
  enableDateFields($('body'));
  enableDateTimeFields($('body'));
})

$(document).on('sprinkles:update', function(event) {
  enableDateFields($(event.target));
  enableDateTimeFields($(event.target));
})

$(document).on('config:add_field', function(event) {
  enableDateFields($(event.target));
  enableDateTimeFields($(event.target));
})
