$(document).on('turbolinks:load', function() {
  $('.el-tablo.a').click(function() {
    if (!window.eltablolinkClicked) {
      Turbolinks.visit($(this).attr('href'));
    }
  });


  // Allow links inside .el-tablo.a to function normally.
  $('.el-tablo.a a').click(function(e) {
    window.eltablolinkClicked = true;
    setTimeout(function() {
      window.eltablolinkClicked = false;
    }, 50)
  });
});
