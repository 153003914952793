var moment = require('moment');

$(document).on('turbolinks:load sprinkles:update', function() {
  $.fn.dataTable.ext.search = $.fn.dataTable.ext.search || [];

  var $table = $('.datatable-enabled:not(.dataTable)');

  var datatable = $table.DataTable({
    "lengthChange": false,
    "ordering": true,
    "order": [[ 0, "asc" ]],
    pageLength: $table.data('page-length') || 12,
    "fnDrawCallback": function (oSettings) {
      var pgr = $(oSettings.nTableWrapper).find('.dataTables_paginate')
      if (oSettings._iDisplayLength > oSettings.fnRecordsDisplay()) {
        pgr.hide();
      } else {
        pgr.show()
      }
    }
  }).on( 'draw', function () {
    $('[data-toggle="tooltip"]').tooltip();
  });

  var $filters = $table.parents('.element-box').find('.filters');

  $filters.find('[data-numeric-filter-col]').each(function() {
    var that = this;
    var $filterParent = $(that).parents('.filters');
    $.fn.dataTable.ext.search.push(
      function( settings, data, dataIndex ) {
        // Make sure this filter belongs to this $table.
        if ($filterParent[0] != $filters[0]) {
          return true;
        }

        return numericFilter(data, $(that).val(), $(that).data('numeric-filter-col'));
      }
    );
  });

  $filters.find('[data-multi-filter]').each(function(i, el) {
    var that = this;
    $.fn.dataTable.ext.search.push(
      function( settings, data, dataIndex ) {
        var value, selectedValue, selectedValueArray = $(that).val();

        // Make sure this filter belongs to this $table.
        if ($('select[data-multi-filter]')[0] != $(el)[0]) {
          return true;
        }

        if (!Array.isArray(selectedValueArray)) {
          selectedValueArray = [selectedValueArray]
        }

        for (var i = 0; i < selectedValueArray.length; i++) {
          var selectedValue = selectedValueArray[i];
          var column = selectedValue.match(/col:(\d+)/)[1];

          if (selectedValue.match(/num:/)) {
            // Numeric search.
            value = selectedValue.match(/num:([\-\d]+)/)[1];
            if (!numericFilter(data, value, column)) {
              return false;
            }
          }
          else if (data[column]) {
            // Normal text search.
            value = selectedValue.match(/text:(.*)/)[1];
            if (!data[column].match(value)) {
              return false;
            }
          }
        }

        return true
      }
    );
  });

  function numericFilter(data, selectedValue, filterColumn) {
    var min, max, columnValue = parseFloat( data[parseInt(filterColumn)] );

    if (selectedValue === '') {
      return true;
    }

    if (columnValue === '') {
      return false;
    }

    if (selectedValue.match('-')) {
      max = parseInt( selectedValue.split('-')[1] );
      min = parseInt( selectedValue.split('-')[0] );
    }
    else {
      max = parseInt( selectedValue );
      min = parseInt( selectedValue );
    }

    return columnValue >= min && columnValue <= max;
  }

  $filters.find('[data-date-filter-col]').each(function() {
    var that = this;
    var $filterParent = $(that).parents('.filters');
    $.fn.dataTable.ext.search.push(
      function( settings, data, dataIndex ) {

        // Make sure this filter belongs to this $table.
        if ($filterParent[0] != $filters[0]) {
          return true;
        }


        var selectedValue = $(that).val();
        var filterColumn = $(that).data('date-filter-col');
        var min, max, columnValue, columnValueAsDate;
        var columnValue = data[parseInt(filterColumn)]

        if (selectedValue === '') {
          return true;
        }

        if (columnValue === '') {
          return false;
        }

        columnValueAsDate = moment.utc(columnValue, "DD MMM YYYY");

        if (selectedValue.match('-')) {
          max = moment.utc( selectedValue.split('-')[1] );
          min = moment.utc( selectedValue.split('-')[0] );
        }
        else {
          max = moment.utc( selectedValue );
          min = moment.utc( selectedValue );
        }

        return columnValueAsDate >= min && columnValueAsDate <= max;
      }
    );
  });

  function filter() {
    $('[data-filter-col]').each(function(idx, item) {
      var val = $(item).val();
      if (val == '--any--') {
        val = "";
      }

      if (Array.isArray(val)) {
        val = val.join(" ")
      }

      datatable.columns($(item).data('filter-col'))
        .search(val);
    });

    datatable.draw();
    // Re-enable tooltips.
    $('[data-toggle="tooltip"]').tooltip();
  }

  $filters.on('change keyup', '[data-filter-col], [data-numeric-filter-col], [data-date-filter-col], [data-multi-filter]', filter);

  // Fix the double click needed on filtered lists.
  $('.dataTable').on('mouseover', function() {
    $('body').find('input, select').blur();
  })
});
